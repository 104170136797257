import {tableOptionsToQueryParams} from "@/utils/table-util";
import _ from 'lodash';

export default {
  created: function () {
    this.debouncedSearch = _.debounce(this.searchAndFilter, 500)
  },
  data() {
    return {
      filters: {$search:''},confimedFilters: {$search:''},
    }
  },
  mounted() {
    this.loadTableData();
  },
  methods: {
    searchOnTextChange(){
      this.debouncedSearch();
    },
    tableOptionsToQueryParams(page){
      return tableOptionsToQueryParams(page, this.table);
    },
    searchAndFilter(){
      this.confimedFilters = JSON.parse(JSON.stringify(this.filters));
      this.loadTableData();
    },
  }
}