
<template>
  <div>
   <v-dialog
        v-model="showDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card tile>
          <v-toolbar
            flat
            dark
            color="indigo"
          >
            <v-btn
              icon
              dark
              @click="showDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Case Review</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <!-- <v-btn dark text @click="dialog = false" > Save </v-btn> -->
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-list
                three-line
                subheader
              >
                <v-subheader>Case Entry</v-subheader>
                <v-row>
                  <v-col cols="12" lg="3" md="4" sm="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Entry UUID</v-list-item-title>
                        <v-list-item-subtitle>{{model.entryUuid}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" lg="3" md="4" sm="6" xs="12">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Date</v-list-item-title>
                        <v-list-item-subtitle>{{model.entryDate | calendar}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" lg="3" md="4">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Entry Result</v-list-item-title>
                        <v-list-item-subtitle>{{model.entryResult}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-list>
              <v-divider></v-divider>
              <v-list
                three-line
                subheader
              >
                <v-subheader>Patient Info</v-subheader>
                <v-row>
                  <v-col cols="12" lg="3" md="4" sm="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>First Name</v-list-item-title>
                        <v-list-item-subtitle>{{model.firstName}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" lg="3" md="4" sm="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Last name</v-list-item-title>
                        <v-list-item-subtitle>{{model.lastName}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" lg="3" md="4" sm="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Age</v-list-item-title>
                        <v-list-item-subtitle>{{model.age}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" lg="3" md="4" sm="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Address</v-list-item-title>
                        <v-list-item-subtitle>{{model.address}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" lg="3" md="4" sm="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Gender</v-list-item-title>
                        <v-list-item-subtitle>{{model.gender}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" lg="3" md="4" sm="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Guardian name</v-list-item-title>
                        <v-list-item-subtitle>{{model.guardianFullname}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" lg="3" md="4" sm="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Guardian phone</v-list-item-title>
                        <v-list-item-subtitle>{{model.guardianPhone}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" lg="3" md="4" sm="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Referral location</v-list-item-title>
                        <v-list-item-subtitle>{{model.referralLocation}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-list>
              <v-divider></v-divider>
              <v-list
                three-line
                subheader
              >
                <v-subheader>Case</v-subheader>
                <v-row>
                  <v-col cols="12" lg="3" md="4" sm="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Hospital</v-list-item-title>
                        <v-list-item-subtitle>{{model.hospitalName}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" lg="3" md="4" sm="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Doctor</v-list-item-title>
                        <v-list-item-subtitle>{{model.doctorName}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" lg="3" md="4" sm="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Status</v-list-item-title>
                        <v-list-item-subtitle>{{model.status}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" lg="3" md="4" sm="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Completion date</v-list-item-title>
                        <v-list-item-subtitle>{{model.completionDate | calendar}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Doctor's remark</v-list-item-title>
                        <v-list-item-subtitle>{{model.doctorRemark}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" lg="3" md="4" sm="6" v-if="model.districtCode">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>District</v-list-item-title>
                          <v-list-item-subtitle><span v-if="model.districtCode">{{model.districtCode+" "+model.districtName}}</span> <span v-else>...</span></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                </v-row>
              </v-list>
            </v-container>
          </v-card-text>

          <div style="flex: 1 1 auto;"></div>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import AppFilters from "@/filters/index"

export default {
  name: 'CaseViewer',
  created() {

  },
  data() {
    return {
      showDialog: false,
      model: {}
    }
  },
  props: {
  },
  filters:{
    ...AppFilters
  },
  methods: {
    show(model){
      this.model = model;
      this.showDialog =true;
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

