<template>
  <v-container>
    <case-viewer ref="caseViewer"></case-viewer>
    <v-card elevation="0">
      <v-card-title>
        <v-toolbar-title>Referrals</v-toolbar-title>
       <v-spacer></v-spacer>
       <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue lighten-5"
              class="primary--text text--darken-1 mr-2"
              dark
              v-bind="attrs"
              v-on="on" elevation="0"
            >
              <v-icon size="20" class="mr-1">mdi-file-export-outline</v-icon> Export
            </v-btn>
          </template>
          <v-list dense >
            <div class="caption px-3">
              <b class="primary--text">CHOOSE AN OPTION </b>
            </div>
            <!-- <v-list-item link>
               <v-list-item-title> <v-icon class="grey--text mr-1" size="20">mdi-printer-settings</v-icon> Print</v-list-item-title>
            </v-list-item> -->
            <v-list-item link @click="exportData('excel')">
              <v-list-item-title><v-icon class="grey--text mr-1" size="20">mdi-microsoft-excel</v-icon> Excel</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item link>
              <v-list-item-title><v-icon class="grey--text mr-1" size="20">mdi-file-excel</v-icon> Csv</v-list-item-title>
            </v-list-item> -->
            <!-- <v-list-item link  @click="exportData('pdf')">
              <v-list-item-title><v-icon class="grey--text mr-1" size="20">mdi-file-document-outline</v-icon> Pdf</v-list-item-title>
            </v-list-item> -->
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-title>
        <div style="width:300px">
          <v-text-field v-model="searchText" label="Search" name="search" dense outlined hide-details prepend-inner-icon="mdi-magnify" class="mr-3" ></v-text-field>
        </div>
        <v-btn elevation="0" height="41" color="blue lighten-5" class="primary--text text--darken-1" @click="searchAndFilter()"> Search</v-btn>
        <case-filters :loading="table.loading" class="ml-2" @filter="applyFilter"></case-filters>

        <v-btn v-if="selected.length > 0" elevation="1" right title="Delete Account" class="red dark-4 white--text mx-2" @click="bulkDeleteAccount(selected)" >
              <v-icon small> fas fa-trash-alt </v-icon>
              Delete Selected
        </v-btn>
        
      </v-card-title>

      <v-data-table
        :headers="table.headers"
        :items="table.items"
        :single-select="true"
        :server-items-length="table.total"
        :loading="table.loading"
        :options.sync="table.options"
        :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
        class="mx-0 mx-sm-6" item-key="name" show-select v-model="selected"
      >
        <v-card-title>
        <!-- <template v-slot:top> -->
            <!-- <v-container grid-list-sm class="text-center"> -->
              <!-- <v-layout row justify-space-between> -->
                <v-flex md2>
                  <v-switch v-model="caseRefSingleSelect" label="Single select" class="pa-3"></v-switch>
                </v-flex>
              <!-- </v-layout> -->
            <!-- </v-container> -->
        <!-- </template> -->
        </v-card-title>

        <template v-slot:item.name="{ item }">
          <b>{{item.firstName}} {{item.lastName}}</b>
        </template>
        <template v-slot:item.activeStatus="{ item }">
          <b :class="(item.activeStatus? 'green': 'red')+'--text d-flex flex-nowrap align-center'"> <v-icon class="mr-1" size="8" :color="(item.activeStatus? 'green': 'red')">mdi-checkbox-blank-circle</v-icon> {{item.activeStatus?'active':'disabled'}}</b>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex flex-nowrap">
            <v-hover v-slot="{ hover }" open-delay="100" >
              <v-btn icon :color="hover?'primary': 'grey'" class="grey lighten-4" @click="openItem(item)" >
                <v-icon small> fas fa-arrow-right </v-icon>
              </v-btn>
            </v-hover>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script >
import TablePage from "@/mixins/table-page";
// import AppFilters from "@/filters/index";
import {downloadFile} from "@/utils/download-util";
import CaseFilters from './_components/CaseFilters.vue';
import CaseViewer from './_components/CaseViewer.vue';

export default {
  components:{
    CaseFilters,
    CaseViewer
  },
  mixins:[TablePage],
  data: () => ({
    searchText: "",
    confimedFilters: {$search:''},
    caseRefSingleSelect: false,
    selected: [],
    table:{
      loading: false,
      headers: [
        { text: 'Date', value: 'createdAt', sortable: false },
        { text: 'Patient', value: 'name', sortable: false  },
        { text: 'Doctor', value: 'doctorName', sortable: false  },
        { text: 'Status', value: 'status', sortable: false, width:'100px'  },
        { text: 'Remark', value: 'doctorRemark', sortable: false, },
        { text: 'Manage', value: 'actions', sortable: false,  width:'100px'  },
      ],
      items:[],
      total: null,
      options:{},
    },
  }),
  mounted() {
  },
  watch:{
    'table.options.page': function(){this.loadTableData(this.table.options.page)},
    'accountStatus': function(){ this.loadTableData(1) },
  },
  methods:{
    openItem(item){
      this.$refs.caseViewer.show(item);
    },
    searchAndFilter(){
      this.confimedFilters.$search = this.searchText;
      this.loadTableData();
    },
    applyFilter(filter){
      this.confimedFilters = {
        ...JSON.parse(JSON.stringify(filter)),
        $search: this.confimedFilters.$search,
      }
      this.loadTableData();
    },
    loadTableData(page=0){
      this.table.loading=true;
      this.$http.get(`referral-cases`, {params: { ...this.confimedFilters, ...this.tableOptionsToQueryParams(page), $includes:['doctorName', 'hospitalName', 'districtCode', 'districtName']} })
      .then(response => {
        this.table.items = response.body.data;
        this.table.total= response.body.total;
        this.table.options.itemsPerPage= response.body.limit;
      }, () => {  })
      .finally(()=>{ this.table.loading=false;});
    },
    async exportData(type){
      try {
        this.table.loading=true;
        let res = await this.$http.get(`referral-cases-export-${type}`, {responseType: 'arraybuffer', params:  { ...this.confimedFilters}} );
        downloadFile(res,type, 'referral-cases');
        this.table.loading=false;
      } catch (error) {
        console.log(error);
      }
    },
    bulkDeleteAccount(items) {
      this.$confirm(`Are you sure you want to delete this ${items.length} selected referral cases`).then((res) => {
          if(res){
            this.table.loading=true;
            this.$http.delete(`referral-cases`, {params: { ids: items.map(i=>i.id) }})
            .then(()=> {
              this.$toast.success('Accounts deleted')
              this.loadTableData(this.table.options.page);
              this.table.loading=false;
            }, error => {
              this.$toast.error(error.body.message)
              this.table.loading=false;
            });
          }
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>