<template>
  <v-container>
    <v-card elevation="0">
      <v-card-title>
        <v-toolbar-title>Regions</v-toolbar-title>
       <v-spacer></v-spacer>
       <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue lighten-5"
              class="primary--text text--darken-1 mr-2"
              dark
              v-bind="attrs"
              v-on="on" elevation="0"
            >
              <v-icon size="20" class="mr-1">mdi-file-export-outline</v-icon> Export
            </v-btn>
          </template>
          <v-list dense >
            <div class="caption px-3">
              <b class="primary--text">CHOOSE AN OPTION </b>
            </div>
            <v-list-item link @click="exportData('excel')">
              <v-list-item-title><v-icon class="grey--text mr-1" size="20">mdi-microsoft-excel</v-icon> Excel</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="exportData('pdf')">
              <v-list-item-title><v-icon class="grey--text mr-1" size="20">mdi-file-document-outline</v-icon> Pdf</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <v-btn color="primary" dark class="mb-0" @click="editAccount({})" elevation="0">
          <v-icon class="mr-1">mdi-shape-circle-plus</v-icon> <span>New Region</span>
        </v-btn> -->
      </v-card-title>
      <v-card-title>
        <div style="width:300px">
          <v-text-field v-model="filters.$search" label="Search" name="search" dense outlined hide-details prepend-inner-icon="mdi-magnify" class="mr-3" ></v-text-field>
        </div>
        <v-btn elevation="0" height="41" color="blue lighten-5" class="primary--text text--darken-1" @click="searchAndFilter()"> Search</v-btn>
      </v-card-title>

      <v-data-table
        :headers="table.headers"
        :items="table.items"
        :server-items-length="table.total"
        :loading="table.loading"
        :options.sync="table.options"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
      >
        <template v-slot:item.actions="{ item }">
          <div class="d-flex flex-nowrap">
            <v-hover v-slot="{ hover }" open-delay="100" >
              <v-btn icon :color="hover?'primary': 'grey'" title="Edit Account" class="" @click="editAccount(item)" >
                <v-icon small> fas fa-pen </v-icon>
              </v-btn>
            </v-hover>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script >
// import FacilityForm from './_components/FacilityForm.vue';
import TablePage from "@/mixins/table-page";
import {downloadFile} from "@/utils/download-util";

export default {
  components: {
  },
  mixins:[TablePage],
  data: () => ({
    singleSelect: false,
    selected: [], formDrawer: true,
    table:{
      loading: false,
      headers: [
        { text: 'Code', value: 'code' },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
      ],
      items:[],
      total: null,
      options:{},
    },
    accountStatus: 0,
    accountStatusVals:['active', 'deactivated', 'deleted']
  }),
  watch:{
    'table.options.page': function(){this.loadTableData(this.table.options.page)},
  },
  methods:{
    loadTableData(page=1){
      this.table.loading=true;
      this.$http.get(`regions`, {params: {...this.confimedFilters, ...this.tableOptionsToQueryParams(page)}})
      .then(response => {
        this.table.items = response.body.data;
        this.table.total= response.body.total;
        this.table.options.itemsPerPage= response.body.limit;
        this.table.loading=false;
      }, err => {
        this.table.loading=false;
        console.log(err);
      });
    },
    async exportData(type){
      try {
        this.table.loading=true;
        let res = await this.$http.get(`regions-export-${type}`, {responseType: 'arraybuffer', params:  { ...this.confimedFilters}} );
        downloadFile(res,type, 'regions');
        this.table.loading=false;
      } catch (error) {
        console.log(error);
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>