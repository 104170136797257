<template>
  <v-container>
    <notification-form ref="formSheet"></notification-form>
    <v-card elevation="0">
      <v-card-title>
        <v-toolbar-title>Notifications</v-toolbar-title>
       <v-spacer></v-spacer>
       <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue lighten-5"
              class="primary--text text--darken-1 mr-2"
              dark
              v-bind="attrs"
              v-on="on" elevation="0"
            >
              <v-icon size="20" class="mr-1">mdi-file-export-outline</v-icon> Export
            </v-btn>
          </template>
          <v-list dense >
            <div class="caption px-3">
              <b class="primary--text">CHOOSE AN OPTION </b>
            </div>
            <!-- <v-list-item link>
               <v-list-item-title> <v-icon class="grey--text mr-1" size="20">mdi-printer-settings</v-icon> Print</v-list-item-title>
            </v-list-item> -->
            <v-list-item link @click="exportData('excel')">
              <v-list-item-title><v-icon class="grey--text mr-1" size="20">mdi-microsoft-excel</v-icon> Excel</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item link>
              <v-list-item-title><v-icon class="grey--text mr-1" size="20">mdi-file-excel</v-icon> Csv</v-list-item-title>
            </v-list-item> -->
            <v-list-item link  @click="exportData('pdf')">
              <v-list-item-title><v-icon class="grey--text mr-1" size="20">mdi-file-document-outline</v-icon> Pdf</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn color="primary" dark class="mb-0" @click="editNotification({ })" elevation="0">
          <v-icon class="mr-1">mdi-shape-circle-plus</v-icon> <span>New Notification</span>
        </v-btn>
      </v-card-title>
      <v-card-title>
        <div style="width:300px">
          <v-text-field v-model="searchText" label="Search" name="search" dense outlined hide-details prepend-inner-icon="mdi-magnify" class="mr-3" ></v-text-field>
          <!-- <v-text-field v-model="filters.$search" label="Search" name="search" dense outlined hide-details prepend-inner-icon="mdi-magnify" class="mr-3" ></v-text-field> -->
        </div>
        <v-btn elevation="0" height="41" color="blue lighten-5" class="primary--text text--darken-1" @click="searchAndFilter()"> Search</v-btn>
        <notification-filter :loading="table.loading" class="ml-2" @filter="applyFilter"></notification-filter>
      </v-card-title>

      <v-data-table
        :headers="table.headers"
        :items="table.items"
        :server-items-length="table.total"
        :loading="table.loading"
        :options.sync="table.options"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
      >
        <template v-slot:item.actions="{ item }">
          <div class="d-flex flex-nowrap">
            <v-hover v-slot="{ hover }" open-delay="100" >
              <v-btn icon :color="hover?'primary': 'grey'" title="Edit Notification" class="" @click="editNotification(item)" >
                <v-icon small> fas fa-pen </v-icon>
              </v-btn>
            </v-hover>
            <!-- <v-hover v-slot="{ hover }" open-delay="100" >
              <v-btn icon :color="hover?'primary': 'grey'" title="Delete Notification" class="" @click="deleteNotification(item)" >
                <v-icon small> fas fa-trash-alt </v-icon>
              </v-btn>
            </v-hover> -->
          </div>
        </template>
        <template v-slot:item.status="{ item }">
          <div class="d-flex flex-nowrap">
            <v-switch v-model="item.activeStatus" inset @change="onActiveStatusChange(item)"></v-switch>
          </div>
        </template>
        <template v-slot:item.isVerified="{ item }">
          <b :class="(item.isVerified? 'green': 'red')+'--text d-flex flex-nowrap align-center'"> <v-icon class="mr-1" size="8" :color="(item.isVerified? 'green': 'red')">mdi-checkbox-blank-circle</v-icon> {{item.isVerified? 'yes' : 'no'}}</b>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script >
import NotificationForm from './_components/NotificationForm.vue';
import NotificationFilter from './_components/NotificationFilters.vue';
import TablePage from "@/mixins/table-page";
import {downloadFile} from "@/utils/download-util";

export default {
  components: {
    NotificationFilter,
    NotificationForm
  },
  mixins:[TablePage],
  data: () => ({
    singleSelect: false,
    selected: [], formDrawer: true,
    searchText: "",
    confimedFilters: {$search:''},
    loadingFilter: false,
    table:{
      loading: false,
      headers: [
        {
          text: 'Campaign',
          align: 'start',
          sortable: false,
          value: 'campaign',
        },
        { text: 'Target', value: 'target' },
        { text: 'Click/Opens', value: 'click_opens' },
       
        { text: 'Created at', value: 'createdAt' },
        // { text: 'Verified', value: 'isVerified' },
        { text: 'Status', value: 'status' },
        { text: '', value: 'actions', sortable: false },
      ],
      items:[],
      total: null,
      options:{},
    },
    NotificationStatus: 0,
    NotificationStatusVals:['active', 'deactivated', 'deleted']
  }),
  watch:{
    'table.options.page': function(){this.loadTableData(this.table.options.page)},
  },
  methods:{
    searchAndFilter(){
      this.confimedFilters.$search = this.searchText;
      this.loadTableData();
    },
    applyFilter(filter){
      this.confimedFilters = {
        ...JSON.parse(JSON.stringify(filter)),
        $search: this.confimedFilters.search,
      }
      this.loadTableData();
    },
    loadTableData(page=1){
      this.table.loading=true;
      this.$http.get(`notifications`, {params: {...this.confimedFilters, ...this.tableOptionsToQueryParams(page)}})
      .then(response => {
        this.table.items = response.body.data;
        this.table.total= response.body.total;
        this.table.options.itemsPerPage= response.body.limit;
        this.table.loading=false;
      }, () => {
        this.table.loading=false;
      });
    },
    onActiveStatusChange(item){
      this.$confirm(`Do you want to ${item.status? 'activate': 'disable'} <b>${item.campaign} </b>'s Notification`).then((res) => {
        if(res){
          this.table.loading=true;
          this.$http.put(`notifications/${item.id}`, {activeStatus: item.status})
          .then((/*res */ ) => {
            this.$toast.success('Notification deactivated')
            this.table.loading=false;
          }, error => {
            item.activeStatus = !item.activeStatus;
            this.$toast.error(error.body.message)
            this.table.loading=false;
          });
        }else{
          item.activeStatus = !item.activeStatus;
        }
      }, ()=>{
      })
    },
    editNotification(model){
      this.$refs.formSheet.show(model, (data)=>{
      this.$refs.formSheet.load();
        (model.id? this.$http.put('notifications/'+model.id, data): this.$http.post('notifications', data))
        .then(()=>{
          this.loadTableData()
          this.$refs.formSheet.load(false);
          this.$refs.formSheet.close();
          this.$toast.success('Notification added')
        }).catch(error=>{
          this.$toast.error(error.body.message)
          this.$refs.formSheet.load(false);
        })
      })
    },
    deleteNotification(item) {
      this.$confirm(`Do you want to delete ${item.id} 's Notification`).then((res) => {
        if(res){
          this.table.loading=true;
          this.$http.delete(`notifications`, {params: {Notification_code: item.code}})
          .then((/*res */ )=> {
            this.$toast.success('Notification updated')
            this.table.loading=false;
          }, error => {
            this.$toast.error(error.body.message)
            this.table.loading=false;
          });
        }
      })
    },
    async exportData(type){
      try {
        this.table.loading=true;
        let res = await this.$http.get(`notifications-export-${type}`, {responseType: 'arraybuffer', params:  { ...this.confimedFilters}} );
        downloadFile(res,type, 'notifications');
        this.table.loading=false;
      } catch (error) {
        console.log(error);
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>