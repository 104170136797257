import moment from 'moment';

const AppFilters={
    age(value){
        if(!value)
            return "";
        return moment().diff(value, 'years');
    },
    entryStatus(entry){
        if(!entry)
            return "";
        if(entry.completionDate)
            return "completed"
        if(entry.doctorId)
            return "accepted"
        return "pending acceptance"
    },
    dateOnly(value){
        return (new Date(value)).toDateString()
    },
    calendar(value){
        if(!value)
            return "";
        return moment(value).calendar();  
    },
    weeklyDays(newM){
        let str ="";
        Object.keys(newM.repeatWeeklyDays).forEach(week => {
          if(newM.repeatWeeklyDays[week]==true)
            str+= str==""?week: ", "+week;
        });
        return str;
    },
    readableTime(time) {
        time = time.split(':');
        time = `${time[0]}:${time[1]}`
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    
        if (time.length > 1) { // If time format correct
          time = time.slice(1); // Remove full string match value
          time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
          time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    },

}
export default AppFilters;