<template>
  <v-container>
    <entry-viewer ref="entryViewer"></entry-viewer>
    <v-card elevation="0">
      <v-card-title>
        <v-toolbar-title>Referral Entries</v-toolbar-title>
       <v-spacer></v-spacer>
       <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue lighten-5"
              class="primary--text text--darken-1 mr-2"
              dark
              v-bind="attrs"
              v-on="on" elevation="0"
            >
              <v-icon size="20" class="mr-1">mdi-file-export-outline</v-icon> Export
            </v-btn>
          </template>
          <v-list dense >
            <div class="caption px-3">
              <b class="primary--text">CHOOSE AN OPTION </b>
            </div>
            <!-- <v-list-item link>
               <v-list-item-title> <v-icon class="grey--text mr-1" size="20">mdi-printer-settings</v-icon> Print</v-list-item-title>
            </v-list-item> -->
            <v-list-item link @click="exportData('excel')">
              <v-list-item-title><v-icon class="grey--text mr-1" size="20">mdi-microsoft-excel</v-icon> Excel</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item link>
              <v-list-item-title><v-icon class="grey--text mr-1" size="20">mdi-file-excel</v-icon> Csv</v-list-item-title>
            </v-list-item> -->
            <v-list-item link  @click="exportData('pdf')">
              <v-list-item-title><v-icon class="grey--text mr-1" size="20">mdi-file-document-outline</v-icon> Pdf</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-title>
        <div style="width:300px">
          <v-text-field v-model="searchText" label="Search" name="search" dense outlined hide-details prepend-inner-icon="mdi-magnify" class="mr-3" ></v-text-field>
        </div>
        <v-btn elevation="0" height="41" color="blue lighten-5" class="primary--text text--darken-1" @click="searchAndFilter()"> Search</v-btn>
        <entry-filters :loading="table.loading" class="ml-2" @filter="applyFilter"></entry-filters>

        <v-btn v-if="selected.length > 0" elevation="1" right title="Delete Account" class="red dark-4 white--text mx-2" @click="bulkDeleteAccount(selected)" >
              <v-icon small> fas fa-trash-alt </v-icon>
              Delete Selected
        </v-btn>

      </v-card-title>

      <v-data-table
        :headers="table.headers"
        :items="table.items"
        :server-items-length="table.total"
        :loading="table.loading"
        :options.sync="table.options"
        :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
        :single-select="entriesRefSingleSelect" item-key="id" show-select v-model="selected">
        <h3>
          {{selected}}
        </h3>
        <template v-slot:top>
            <v-container grid-list-sm class="text-center">
              <v-layout row justify-space-between>
                <v-flex md2>
                  <v-switch v-model="entriesRefSingleSelect" label="Single select" class="pa-3"></v-switch>
                </v-flex>
                 
              </v-layout>
            </v-container>
        </template>
        <template v-slot:item.date="{ item }">
          <i > {{item.date | calendar}}</i>
        </template>
        <template v-slot:item.transferMode="{ item }">
          <i > {{item.transferMode | transferModeName}}</i>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <i > {{item.createdAt | calendar}}</i>
        </template>
        <template v-slot:item.patientName="{ item }">
          <b > {{item.firstName}} {{item.lastName}}</b>
        </template>
        <template v-slot:item.status="{ item }">
          <b > {{item | entryStatus}}</b>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex flex-nowrap">
            <v-hover v-slot="{ hover }" open-delay="100" >
              <v-btn icon :color="hover?'primary': 'grey'" class="grey lighten-4" @click="openItem(item)" >
                <v-icon small> fas fa-arrow-right </v-icon>
              </v-btn>
            </v-hover>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script >
import TablePage from "@/mixins/table-page";
import AppFilters from "@/filters/index"
import {downloadFile} from "@/utils/download-util";
import EntryFilters from './_components/EntryFilters.vue';
import EntryViewer from './_components/EntryViewer.vue';

export default {
  components:{
    EntryFilters,
    EntryViewer
  },
  mixins:[TablePage],
  data: () => ({
    searchText: "",
    confimedFilters: {$search:''},
    loadingFilter: false,
    entriesRefSingleSelect: false,
    selected: [],
    table:{
      loading: false,
      headers: [
        { text: 'Id', value: 'id', sortable: false },
        { text: 'Date', value: 'date', sortable: false },
        { text: 'Patient name', value: 'patientName', sortable: false  },
        { text: 'Age', value: 'age', sortable: false  },
        { text: 'Gender', value: 'gender', sortable: false  },
        { text: 'Address', value: 'address', sortable: false  },
        // { text: 'Guardian', value: 'guardianFullname', sortable: false  },
        // { text: 'Guardian phone', value: 'guardianPhone', sortable: false  },
        // { text: 'Referral Location', value: 'referralLocation', sortable: false  },
        { text: 'Hospital', value: 'hospital', sortable: false  },
        { text: 'Result', value: 'testResult', sortable: false  },
        // { text: 'User', value: 'userPhone', sortable: false  },
        // { text: 'Sender', value: 'senderPhone', sortable: false  },
        { text: 'Status', value: 'status', sortable: false  },
        { text: 'Mode', value: 'transferMode', sortable: false  },
        { text: 'Manage', value: 'actions', sortable: false },
      ],
      items:[],
      total: null,
      options:{},
    },
  }),
  filters:{
    ...AppFilters,
    transferModeName(mode){
      const modes={
        ussd:"ussd", 
        app_ussd:"app ussd", 
        app_internet:"app"
      };
      return modes[mode] || "";
    }
  },
  mounted() {
  },
  watch:{
    'table.options.page': function(){this.loadTableData(this.table.options.page)},
    'accountStatus': function(){ this.loadTableData(1) },
  },
  methods:{
    openItem(item){
      this.$refs.entryViewer.show(item)
    },
    searchAndFilter(){
      this.confimedFilters.$search = this.searchText;
      this.loadTableData();
    },
    applyFilter(filter){
      this.confimedFilters = {
        ...JSON.parse(JSON.stringify(filter)),
        $search: this.confimedFilters.$search,
      }
      this.loadTableData();
    },
    loadTableData(page=1){
      this.table.loading=true;
      this.$http.get(`referral-entries`, {params: { ...this.confimedFilters, ...this.tableOptionsToQueryParams(page), $includes:['doctorName', 'hospitalName', 'districtCode', 'districtName']} })
      .then(response => {
        this.table.items = response.body.data;
        this.table.total= response.body.total;
        this.table.options.itemsPerPage= response.body.limit;
      }, () => {})
      .finally(()=>{ this.table.loading=false;});
    },
    async exportData(type){
      try {
        this.table.loading=true;
        let res = await this.$http.get(`referral-entries-export-${type}`, {responseType: 'arraybuffer', params:  { ...this.confimedFilters}} );
        downloadFile(res,type, 'referral-entries');
        this.table.loading=false;
      } catch (error) {
        console.log(error);
      }
    },
    bulkDeleteAccount(items) {
      this.$confirm(`Are you sure you want to delete this ${items.length} selected referral entries`).then((res) => {
          if(res){
            this.table.loading=true;
            this.$http.delete(`referral-entries`, {params: { ids: items.map(i=>i.id) }})
            .then(()=> {
              this.$toast.success('Referral deleted')
              this.loadTableData(this.table.options.page);
              this.table.loading=false;
            }, error => {
              this.$toast.error(error.body.message)
              this.table.loading=false;
            });
          }
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>