export function downloadFile(res, typeName, filename="export") {
    const info ={
        excel: {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ext: '.xlsx'
        },
        pdf: {
            type: 'application/pdf',
            ext: '.pdf'
        }
    };
    const fileFormat = info[typeName];
    const type = fileFormat.type;
    filename = filename+fileFormat.ext;
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([res.body], {type})

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob)
    return
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob)
    var link = document.createElement('a')
    link.href = data
    link.download = filename
    link.click()
    setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data)
    }, 100)
}