<template>
  <v-app>
    <div class="bg-account-pages py-4 py-sm-0">
      <div class="account-home-btn d-none d-sm-block">
        <router-link to="/" class="text-white">
          <i class="mdi mdi-home h1"></i>
        </router-link>
      </div>
      <section class="height-100vh">
        <div class="display-table">
          <div class="display-table-cell">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-5">
                  <div class="card account-card">
                    <div class="card-body">
                      <div class="text-center mt-3">
                        <h3 class="font-weight-bold">
                          <a href="/" class="text-dark text-uppercase account-pages-logo">
                            <i class="mdi mdi-eye"></i>Eye Screening App
                          </a>
                        </h3>
                        <p class="text-muted">Sign in to continue to Eye Screening Admin.</p>
                      </div>
                      <div class="p-3">
                        <v-tabs  v-model="tabIndex" centered >
                          <!-- <v-tab >User</v-tab> -->
                          <v-tab >Admin</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tabIndex" class="mt-0">
                          <!-- <v-tab-item>
                            <v-form
                              ref="form"
                              v-model="valid"
                              lazy-validation
                              class="w-100"
                              style="max-width:400px"
                            >
                              <v-text-field
                                v-model="model.phone"
                                :rules="phoneRules"
                                label="Phone Number"
                                type="tel"
                                required
                                filled
                                rounded
                              ></v-text-field>
                              <v-text-field
                                v-model="model.pin"
                                :rules="pinRules"
                                label="Pin"
                                type="password"
                                required
                                filled
                                rounded
                              ></v-text-field>
                              <v-btn
                                :disabled="!valid"
                                color="primary"
                                class="w-100 mb-4"
                                large
                                @click="submit('local-user')"
                                :loading="loading"
                                elevation="0"
                              >Login</v-btn>
                            </v-form>
                          </v-tab-item> -->
                          <v-tab-item>
                            <v-form
                              ref="form"
                              v-model="valid"
                              lazy-validation
                              class="w-100"
                              style="max-width:400px"
                            >
                              <v-text-field
                                v-model="model.email"
                                :rules="emailRules"
                                label="E-mail"
                                required
                                filled
                                rounded
                              ></v-text-field>
                              <v-text-field
                                v-model="model.password"
                                :rules="passwordRules"
                                label="Password"
                                type="password"
                                required
                                filled
                                rounded
                              ></v-text-field>
                              <v-btn
                                :disabled="!valid"
                                color="primary"
                                class="w-100 mb-4"
                                large
                                @click="submit('local-admin')"
                                :loading="loading"
                                elevation="0"
                              >Login</v-btn>
                            </v-form>
                          </v-tab-item>
                        </v-tabs-items>
                        
                      </div>
                    </div>
                  </div>
                  <!-- end card -->
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </div>
          </div>
        </div>
      </section>
      <!-- end account-pages  -->
    </div>
  </v-app>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Admin',
  components: {
  },
  data: () => ({
    valid: true,
    loading: false,
    tabIndex: 0,
    model: {
      email: '',
      password: '',
      // phone: '0245637946',
      // pin: '1234',
    },
    passwordRules: [
      v => !!v || 'Password is required',
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    pinRules: [
      v => !!v || 'Pin is required',
    ],
    phoneRules: [
      v => !!v || 'Phone is required',
      v => /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g.test(v) || 'Phone number must be valid',
    ],
  }),
  computed:{
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Must be valid e-mail')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    },
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  mounted(){
    this.$auth
    .load()
    .then(() => {
        if (this.$auth.check()) {
          this.$router.push({ path: '/admin' })
        }
    });
  },
  methods:{
    submit (strategy) {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$auth
        .login({
            body: {
              email: this.model.email,
              password: this.model.password,
              strategy: strategy
            },
            redirect: {path: '/admin'},
            remember: 'Rob',
            staySignedIn: true,
            fetchUser: false
        })
        /* eslint-disable */
        .then(res=>{
          this.loading = false;
          localStorage.setItem('currentUser', JSON.stringify(res.body.rds_retval.user));
          this.$auth.user(res.body.rds_retval.user);
        })
        .catch(err=>{
          this.loading = false;
          this.$toast.error(err.body.message);
        })
        ;
      }
    },
  }
}
</script>

<style src="@/assets/landing/css/pe-icon-7-stroke.css" ></style>
<style src="@/assets/landing/css/materialdesignicons.min.css"></style>
<style src="@/assets/landing/css/bootstrap.min.css" ></style>
<style src="@/assets/landing/css/magnific-popup.css"></style>
<style src="@/assets/landing/css/style.css" ></style>
<style src="@/assets/landing/css/colors/cyan.css" id="color-opt"></style>