
<template>
  <div class="text-center">
    <v-menu
      v-model="showMenu"
      :close-on-content-click="false"
      :nudge-width="500"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="indigo"
          dark
          v-bind="attrs"
          v-on="on"
          elevation="0"
          height="41"
          :loading="loading"
        >
          Filter
          <v-icon right dark>
            mdi-filter-variant
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-container>
          <v-row>
            
            <v-col cols="12" :md="mdCol" :sm="smCol" :xs="xsCol">
              <v-text-field filled dense rounded v-model="model.campaign"  label="Campaign" ></v-text-field>
            </v-col>
            <v-col cols="12" :md="mdCol" :sm="smCol" :xs="xsCol">
              <v-text-field filled dense rounded v-model="model.status" label="Status" ></v-text-field>
            </v-col>
            
            <v-col cols="6" :md="mdCol" :sm="smCol" :xs="xsCol">
          <v-select filled dense rounded v-model="model.target" label="Target" item-text="label" item-value="target" 
          :items="['male', 'female']"
      
           ></v-select>
            </v-col>
            
            <v-col cols="6" :md="mdCol" :sm="smCol" :xs="xsCol">
              <v-select filled dense rounded v-model="model.click_opens" label="Click/Opens" item-text="label"  ></v-select>
            </v-col>
                <!-- :items="formResources.userTypes" -->
            <!-- date filter -->
            <!-- <v-col cols="12" md="4" sm="6" xs="12">
              <label for="">Date range</label>
              <v-menu ref="dateRangeMenu" v-model="showDatePicker" :close-on-content-click="false" :return-value.sync="model.dateRange" transition="scale-transition" offset-y min-width="auto" >
                <template v-slot:activator="{ on, attrs }">
                  <v-combobox v-model="model.dateRange" filled rounded multiple chips small-chips label="" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" ></v-combobox>
                </template>
                <v-date-picker v-model="model.dateRange" range no-title scrollable color="primary" >
                  <v-btn text color="error" :disabled="!model.dateRange" @click="model.dateRange=null;" > Clear </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="showDatePicker = false" > Cancel </v-btn>
                  <v-btn text color="primary" @click="$refs.dateRangeMenu.save(model.dateRange)" > OK </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col> -->
          </v-row>
        </v-container>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="red" text @click="showMenu=false">Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="model={}; ageRangeValue=[0, 20]">Clear</v-btn>
          <v-btn color="primary" text @click="filter()">Filter</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters,  mapActions} from 'vuex'
export default {
  name: 'NotificationFilter',
  created() {

  },
  data() {
    return {
      showMenu: false,
      showDatePicker: false,
      mdCol:"3", smCol:"4", xsCol:"6",
      ageRangeValue:[0, 20],
      model: {
        
      }
    }
  },
  props: {
    loading: { default: false }
  },
  computed:{
    ...mapGetters(['formResources'])
  },
  mounted() {
    this.loadFormResources(['userTypes', 'facilities']);
  },
  methods: {    
    ...mapActions(['loadFormResources']),
   filter(){
     this.showMenu = false,
     this.$emit('filter', {
       ...this.model,
       ...(this.ageRangeValue[0]? {ageRange: {from: this.ageRangeValue[0], to: this.ageRangeValue[1]}}: {} )
     })
   }
  },
}
</script>

<style lang="scss" scoped>

</style>

