<template>
  <v-container>
    <!-- <user-account-form ref="formSheet"></user-account-form> -->
    <v-card elevation="0">
      <v-chip
      pill
      v-for="(item, index) in items" 
        :key="index" class="result-options-chip mr-3 mt-2 rounded bg-red"
      >
          <strong class="text-muted">({{item.id}})</strong>
        <div class="option-name">
          <strong v-if="!editItem || editItem.id != item.id" class="mr-3 ml-1">{{ item.name }}</strong>
          <div v-else >
            <v-text-field class="" autofocus filled dense  placeholder="enter option"
            v-model="editItem.name" @blur="onOptionBlur(item)"></v-text-field>
          </div>
        </div>
        <v-icon v-if="!editItem || editItem.id != item.id" @click="editItem=item; editItemCopy={...editItem}">mdi-pencil-circle-outline</v-icon>
        <v-icon v-if="!editItem || editItem.id != item.id" @click="removeItem(item)">mdi-close-circle-outline</v-icon>
        <v-icon v-if="editItem && editItem.id == item.id" @click="saveOption(item)">mdi-content-save</v-icon>
      </v-chip>
      <v-btn class="mt-2" elevation="0" @click="newOption()">New Option</v-btn>
    </v-card>
  </v-container>
</template>

<script >

export default {
  components: {
  },
  mixins:[],
  data: () => ({
    editItem: null,
    editItemCopy: null,
    items:[
    ],
    loading: false,
  }),
  mounted(){
    this.loadTableData()
  },
  methods:{
    newOption(){
      const opt ={id: 'new'+this.items.length};
      this.items.push(opt);
      this.editItem = opt;
    },
    onOptionBlur(item){
      if(item.id.toString().includes('new')){
        if(!item.name)
        this.items.splice(this.items.indexOf(item), 1)
      }else{
        this.editItem.name = this.editItemCopy.name
      }
    },
    removeItem(item){
      if(item.id.toString().includes('new')){
        this.items.splice(this.items.indexOf(item), 1)
      }else{
        this.$confirm(`Do you want to remove ${item.name}`).then((res) => {
          if(res){
            this.loading=true;
            this.$http.delete(`result-options/${item.id}`, {params: {}})
            .then((/*res */ )=> {
              this.$toast.success('option deleted')
              this.loading=false;
              this.loadTableData()
            }, error => {
              this.$toast.error(error.body.message)
              this.loading=false;
            });
          }
        })
      }
    },
    loadTableData(){
      this.loading=true;
      this.$http.get(`result-options`, {params: { $limit: 100}})
      .then(response => {
        this.items = response.body.data;
        this.loading=false;
      }, err => {
        this.loading=false;
        console.log(err);
      });
    },
    saveOption(item){
      this.loading=true;
      const data = {...item}
      if(data.id.toString().includes('new'))
        delete data.id,
      (data.id? this.$http.put('result-options/'+data.id, data): this.$http.post('result-options', data))
      .then(()=>{
        this.loadTableData()
        this.loading=false;
        this.$toast.success('Saved')
      }).catch(error=>{
        this.loading=false;
        this.$toast.error(error.body.message)
      })
    },
  }
}
</script>

<style lang="scss">
  .result-options-chip{
    width: 250px;

    .v-chip__content{
      display: flex;
      width: 100%;

      .option-name{
        flex: 1;
      }
    }
    .v-text-field__details{
      display: none;
    }
  }

</style>