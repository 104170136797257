
<template>
  <v-col cols="6" md="3">
    <router-link :to="link">
      <v-card elevation="0" :color="color" height="115px" style="position: relative;">
        <div class="d-flex flex-column justify-center h-100 pa-5" style="position: absolute; top:0; left:0;">
          <h2 :class="`${textColor}`">{{count}}</h2>
          <p :class="`text-overline ma-0 ${textColor}`">{{title}}</p>
        </div>
        <div class="d-flex flex-column align-end justify-center h-100 w-100 pa-5" style="position: absolute; top:0; left:0;">
          <v-icon :size="iconSize" :color="iconColor" style="opacity:0.4">{{icon}}</v-icon>
        </div>
      </v-card>
    </router-link>
    
  </v-col>
</template>

<script>
export default {
  name: 'CountButton',
  created() {

  },
  data() {
    return {
    }
  },
  props: {
   title: {default: 'title'},
   count: {default: '0,000'},
   color: {default: 'primary lighten-1'},
   textColor: {default: 'white--text'},
   icon: {default: 'mdi-account'},
   iconSize: {default: '80px'},
   iconColor: {default: 'white'},
   link:{ default: ""}
  },
  methods: {
   
  },
}
</script>

<style lang="scss" scoped>

</style>

