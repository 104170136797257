
<template>
  <v-container>
    <v-card flat>
      <v-tabs icons-and-text v-model="tab" class="px-4">
        <v-tab class="text-capitalize text-subtitle-2"> <div><v-icon size="20" class="mr-3">mdi-select-group</v-icon>Districts</div></v-tab>
        <v-tab class="text-capitalize text-subtitle-2"> <div><v-icon size="20" class="mr-3">mdi-map-legend</v-icon>Regions</div></v-tab>
        <v-tab class="text-capitalize text-subtitle-2"> <div><v-icon size="20" class="mr-3">mdi-account-details-outline</v-icon>Result options</div></v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tab">
        <v-tab-item class="px-8">
          <districts/>
        </v-tab-item>
        <v-tab-item class="px-8">
          <regions/>
        </v-tab-item>
        <v-tab-item class="px-8">
          <result-options></result-options>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import Districts from './_tabs/Districts.vue'
import Regions from './_tabs/Regions.vue'
import ResultOptions from './_tabs/ResultOptions.vue'
export default {
  components: {
    ResultOptions,
    Regions,
    Districts, 
    },
  name: 'Index',
  created() {

  },
  data() {
    return {
      tab: null,
    }
  },
  props: {
   
  },
  methods: {
   
  },
}
</script>

<style lang="scss" scoped>

</style>

