
<template>
  <div class="text-center">
    <v-menu
      v-model="showMenu"
      :close-on-content-click="false"
      :nudge-width="500"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="indigo"
          dark
          v-bind="attrs"
          v-on="on"
          elevation="0"
          height="41"
          :loading="loading"
        >
          Filter
          <v-icon right dark>
            mdi-filter-variant
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-container>
          <v-row>
            <v-col cols="12" :md="mdCol" :sm="smCol" :xs="xsCol">
              <v-text-field filled dense rounded v-model="model.uuid" label="UUID" required ></v-text-field>
            </v-col>
            <v-col cols="12" :md="mdCol" :sm="smCol" :xs="xsCol">
              <v-text-field filled dense rounded v-model="model.firstName" label="First name" required ></v-text-field>
            </v-col>
            <v-col cols="12" :md="mdCol" :sm="smCol" :xs="xsCol">
              <v-text-field filled dense rounded v-model="model.lastName" label="Last name" required ></v-text-field>
            </v-col>
            <v-col cols="12" :md="mdCol" :sm="smCol" :xs="xsCol">
              <v-text-field filled dense rounded v-model="model.address" label="Address" required ></v-text-field>
            </v-col>
            <v-col cols="12" :md="mdCol" :sm="smCol" :xs="xsCol">
              <label for="">Age Range</label>
              <v-range-slider hint="I'm a hint" min="1" max="20" color="primary" v-model="ageRangeValue">
                <template v-slot:prepend><b>{{ageRangeValue[0]}}</b></template>
                <template v-slot:append><b>{{ageRangeValue[1]}}</b></template>
              </v-range-slider>
            </v-col>
            <v-col cols="12" :md="mdCol" :sm="smCol" :xs="xsCol">
              <label for="">Gender</label>
              <v-chip-group multiple active-class="primary--text" v-model="model.gender">
                <v-chip v-for="tag in ['male', 'female']" :key="tag" filter :value="tag">
                  {{ tag }}
                </v-chip>
              </v-chip-group>
            </v-col>
            <!-- <v-col cols="12" :md="mdCol" :sm="smCol" :xs="xsCol">
              <v-select filled dense rounded v-model="model.gender" label="Gender" item-text="label" :items="['male', 'female']" ></v-select>
            </v-col> -->
            <v-col cols="12" :md="mdCol" :sm="smCol" :xs="xsCol">
              <v-text-field v-model="model.guardianFullname" filled dense rounded label="Guardian name"></v-text-field>
            </v-col>
            <v-col cols="12" :md="mdCol" :sm="smCol" :xs="xsCol">
              <v-text-field filled dense rounded v-model="model.guardianPhone" label="Guardian phone"></v-text-field>
            </v-col>
            <v-col cols="12" :md="mdCol" :sm="smCol" :xs="xsCol">
              <v-text-field filled dense rounded v-model="model.referralLocation" label="Referral location"></v-text-field>
            </v-col>
            <v-col cols="12" :md="mdCol" :sm="smCol" :xs="xsCol">
              <v-text-field filled dense rounded v-model="model.hospital" label="hospital"></v-text-field>
            </v-col>
            <v-col cols="12" :md="mdCol" :sm="smCol" :xs="xsCol">
              <v-text-field filled dense rounded v-model="model.testResult" label="testResult"></v-text-field>
            </v-col>
            <v-col cols="12" :md="mdCol" :sm="smCol" :xs="xsCol">
              <v-text-field filled dense rounded v-model="model.userName" label="Sender"></v-text-field>
            </v-col>
            <v-col cols="12" :md="mdCol" :sm="smCol" :xs="xsCol">
              <v-text-field filled dense rounded v-model="model.userPhone" label="Sender's phone"></v-text-field>
            </v-col>
            <!-- <v-col cols="12" :md="mdCol" :sm="smCol" :xs="xsCol">
              <div>
                <v-menu ref="menu" v-model="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field filled dense rounded v-model="model.date" label="Date" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="model.date"></v-date-picker>
                </v-menu>
              </div>    
            </v-col> -->
            <v-col cols="12" md="4" sm="6" xs="12">
              <label for="">Status</label>
              <v-chip-group active-class="primary--text" v-model="model.status">
                <v-chip v-for="tag in ['pending', 'accepted', 'complete']" :key="tag" filter :value="tag">
                  {{ tag }}
                </v-chip>
              </v-chip-group>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12">
              <label for="">Transfer mode</label>
              <v-chip-group multiple active-class="primary--text" v-model="model.transferModes">
                <v-chip v-for="tag in ['ussd', 'app_internet', 'app_ussd']" :key="tag" filter :value="tag">
                  {{ tag }}
                </v-chip>
              </v-chip-group>
            </v-col>
            <!-- date filter -->
            <v-col cols="12" md="4" sm="6" xs="12">
              <label for="">Date range</label>
              <v-menu ref="dateRangeMenu" v-model="showDatePicker" :close-on-content-click="false" :return-value.sync="model.dateRange" transition="scale-transition" offset-y min-width="auto" >
                <template v-slot:activator="{ on, attrs }">
                  <v-combobox v-model="model.dateRange" filled rounded multiple chips small-chips label="" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" ></v-combobox>
                </template>
                <v-date-picker v-model="model.dateRange" range no-title scrollable color="primary" >
                  <v-btn text color="error" :disabled="!model.dateRange" @click="model.dateRange=null;" > Clear </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="showDatePicker = false" > Cancel </v-btn>
                  <v-btn text color="primary" @click="$refs.dateRangeMenu.save(model.dateRange)" > OK </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>

        <v-list>
          <!-- <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="message"
                color="purple"
              ></v-switch>
            </v-list-item-action>
            <v-list-item-title>Enable messages</v-list-item-title>
          </v-list-item> -->

          <!-- <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="hints"
                color="purple"
              ></v-switch>
            </v-list-item-action>
            <v-list-item-title>Enable hints</v-list-item-title>
          </v-list-item> -->
        </v-list>

        <v-card-actions>
          <v-btn color="red" text @click="showMenu=false">Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="model={}; ageRangeValue=[0, 20]">Clear</v-btn>
          <v-btn color="primary" text @click="filter()">Filter</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'EntryFilters',
  created() {

  },
  data() {
    return {
      showMenu: false,
      showDatePicker: false,
      mdCol:"3", smCol:"4", xsCol:"6",
      ageRangeValue:[0, 20],
      model: {
        
      }
    }
  },
  props: {
    loading: { default: false }
  },
  methods: {
   filter(){
     this.showMenu = false,
     this.$emit('filter', {
       ...this.model,
       ...(this.ageRangeValue[0]? {ageRange: {from: this.ageRangeValue[0], to: this.ageRangeValue[1]}}: {} )
     })
   }
  },
}
</script>

<style lang="scss" scoped>

</style>

