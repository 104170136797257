
<template>
  <v-container>
    <v-row>
      <count-button color="primary lighten-1" title="Total Entries" :count="formatNumber(enteriesCounts.all)" icon="mdi-emoticon-sick" iconSize="90px" link="referral-entries"></count-button>
      <count-button color="primary" title="Pending Entries" :count="formatNumber(enteriesCounts.pending)" icon="mdi-reload-alert" iconSize="85px" link="referral-entries"></count-button>
      <count-button color="primary darken-1" title="Ongoin Cases" :count="formatNumber(casesCounts.ongoing)" icon="mdi-doctor" iconSize="85px" link="referrals"></count-button>
      <count-button color="primary darken-2" title="Completed Cases" :count="formatNumber(casesCounts.completed)" icon="mdi-medical-bag" link="referrals"></count-button>
      <count-button color="primary darken-3" title="Facilities" :count="formatNumber(entitiesCounts.facilities)" icon="mdi-hospital-building" link="facilities"></count-button>
      <count-button color="primary darken-3" title="Users" :count="formatNumber(entitiesCounts.users)" icon="mdi-account-group" iconSize="80px" link="users"></count-button>
      <count-button color="primary darken-3" title="Admins" :count="formatNumber(entitiesCounts.admins)" icon="mdi-account-supervisor-circle" link="accounts"></count-button>
    </v-row>
    <!-- <v-row>
      <v-col cols="12" md="6">
        <v-card color="blue-grey lighten-5" class="pa-3" elevation="0">
          <template slot.title>
            Trips by months of the year
          </template>
          <weekly-trips style="height:250px"></weekly-trips>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card color="blue-grey lighten-5" class="pa-3" elevation="0">
          <template slot.title>
            Top Ambulance companies
          </template>
          <top-vehicle-companies style="height:250px"></top-vehicle-companies>
        </v-card>
      </v-col>
      <v-col cols="12" md="5">
        <v-card color="blue-grey lighten-5" class="pa-3" elevation="0">
          <template slot.title>
            Top 3 Drivers
          </template>
          <top-3-drivers style="height:250px"></top-3-drivers>
        </v-card>
      </v-col>
      <v-col cols="12" md="7">
        <v-card color="blue-grey lighten-5" class="pa-3" elevation="0">
          <template slot.title>
            Rate of failed trips
          </template>
          <failed-trip-rate style="height:250px"></failed-trip-rate>
        </v-card>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import CountButton from './_components/CountButton.vue'
// import FailedTripRate from './_components/FailedTripRate.vue'
// import Top3Drivers from './_components/Top3Drivers.vue'
// import TopVehicleCompanies from './_components/TopVehicleCompanies.vue'
// import WeeklyTrips from './_components/WeeklyTrips.vue'
export default {
  components: { 
    CountButton,
    // WeeklyTrips,
    // TopVehicleCompanies,
    // Top3Drivers,
    // FailedTripRate
  },
  name: 'Index',
  created() {

  },
  data() {
    return {
      casesCounts:{},
      enteriesCounts:{},
      entitiesCounts:{},
    }
  },
  props: {
   
  },
  mounted(){
    this.loadCounts();
  },
  methods: {
    loadCounts(){
      this.$http.get(`referral-cases-counts`, {params: {}})
        .then(response => {
          this.casesCounts = response.body
        });

      this.$http.get(`referral-entries-counts`, {params: {}})
        .then(response => {
          this.enteriesCounts = response.body
        });

      this.$http.get(`admin-entities-counts`, {params: {}})
        .then(response => {
          this.entitiesCounts = response.body
        });
    },
    formatNumber(number){
      if(!number)
        return "";
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

