
<template>
  <side-form-sheet ref="formSheet" :title="model.id? 'Edit Account': 'Create New Account'">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field filled dense rounded v-model="model.firstName" :rules="requiredRules" label="First name" required ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="model.lastName" filled dense rounded :rules="requiredRules" label="Last name" required ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-select filled dense rounded v-model="model.gender" :rules="requiredRules" label="Gender" item-text="label" :items="['male', 'female']" ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="model.email" filled dense rounded :rules="emailRules" label="Email"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field filled dense rounded v-model="model.phone" :rules="requiredRules" label="Phone"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-select filled dense rounded v-model="model.role" :rules="requiredRules" label="Role" item-text="label" item-value="id" :items="formResources.adminRoles" ></v-select>
        </v-col>
        <v-col cols="12" md="6" v-if="!model.id">
          <v-text-field filled dense rounded v-model="model.password" type="password" :rules="passRules" label="Password"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" v-if="!model.id">
          <v-text-field filled dense rounded v-model="model.confirmPassword" type="password" :rules="confirmPassRules" label="Confirm Password"></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </side-form-sheet>
</template>

<script>
import SideFormSheet from "../../../../../components/SideFormSheet.vue";
import { mapGetters,  mapActions} from 'vuex'
export default {
  name: "NewAccountForm",
  components: {
    SideFormSheet,
  },
  data() {
    return {
      valid: false,
      formDrawer: true,
      requiredRules: [(v) => !!v || "Field is required"],
      passRules: [
        (v) => !!v || "Field is required",
        (v) =>
          (v && v.length >= 8) || "Password must be more than 8 characters",
      ],
      confirmPassRules: [
        (v) => this.model.password == v || "Password mismatch",
        (v) => !!v || "Field is required",
      ],
      emailRules: [
        (v) => !!v || "Field is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      model: {},
      serverErrors: {},
      roles: [],
    };
  },
  props: {},
  computed:{
    ...mapGetters(['formResources'])
  },
  mounted() {
    this.loadFormResources(['adminRoles']);
  },
  methods: {
    ...mapActions(['loadFormResources']),
    show(model, onSave, onCancel = null) {
      this.model = { ...model };
      this.$refs.formSheet.show(() => {
        onSave(this.model);
      }, onCancel);
    },
    close(){
      this.$refs.formSheet.close();
    },
    load(loading = true) {
      this.$refs.formSheet.load(loading);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

